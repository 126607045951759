import datepicker from 'js-datepicker';
import 'js-datepicker/src/datepicker';

const modal = document.querySelector('.online-modal');
const modalClose = document.querySelectorAll('.js-close-online-modal');
const body = document.querySelector('body');
const initBtns = document.querySelectorAll('.online-consultation-btn-js');
const modalContainer = document.querySelector('.online-modal__container'); // Контейнер модалки

let picker 
let clinicId
let doctorId
let scheduleGlobal
let formId



if(initBtns && initBtns.length > 0) {

  initBtns.forEach(btn => {
    btn.addEventListener('click', () => {
        const id = btn.getAttribute('data-button-id');
        initDatePicker(modal , id);
        modal.classList.add('activeModal'); // Додаємо клас для відкриття модалки
        body.classList.add('noScroll'); // Можна додати клас для блокування прокручування сторінки, якщо потрібно
    });
  });

  // Закриття модального вікна
  modalClose.forEach(btn => {
    btn.addEventListener('click', () => {
        removeDatePicker();
        closeModal()
    });
  });

  modal.addEventListener('click', (e) => {
    // Перевіряємо, чи клікнули поза контейнером
    
    if (!modalContainer.contains(e.target)) { 
        removeDatePicker();
        closeModal()
    }
  });

  const closeModal = () => {
    modal.classList.remove('activeModal');
    body.classList.remove('noScroll');
  }
}
let enabledDates = []

let lastSelectedDate = null
// Налаштування для datepicker
const options = {
    customDays: [ 'НД', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
    customMonths: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    position: 'br',
    minDate: new Date(),
    startDay: 1,
    alwaysShow: true,
    events: getDiscountsDate(),
    disabler: date => {
      const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      return !enabledDates.includes(formattedDate);
    },
    onMonthChange: async instance => {
      const year = instance.currentYear;
      const month = instance.currentMonth;

      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);
      try {
        await changeMonthPicker(firstDayOfMonth, lastDayOfMonth);
        picker.navigate(firstDayOfMonth);
      } catch (error) {
          console.error('Помилка при зміні доступних дат:', error);
      }
    },
    onSelect: ( input, date , instance) => {
      if(!date) {
        parseAvailabledTimes(input.el  , picker , scheduleGlobal , lastSelectedDate , true);
        return
      } 
      parseAvailabledTimes(input.el  , picker , scheduleGlobal , date , true);
    }
};

function formaterDate(input, date, time){
  if(!date ){
    if(lastSelectedDate) date = lastSelectedDate
  } 
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedDate = dd + '.' + mm + '.' + yyyy + ' ' + (time ? time : '') // time
  input.value = formattedDate;
  lastSelectedDate = date
}


function datesMatch(selectedDate) {
  const eventDates = getDiscountsDate(); // Отримуємо масив дат
  return eventDates.some(eventDate => {
    // Форматуємо обидві дати у формат YYYY-MM-DD для коректного порівняння
    const eventDateFormatted = formatDateForDateMatch(eventDate);
    const selectedDateFormatted = formatDateForDateMatch(selectedDate);
    return eventDateFormatted === selectedDateFormatted;
  });
}

function formatDateForDateMatch(date) {
  return date.getFullYear() + "-" +
    String(date.getMonth() + 1).padStart(2, "0") + "-" +
    String(date.getDate()).padStart(2, "0");
}

function generateRadios(inputTrigger , selectedDate ,  availableTimes){
  const parentModal = inputTrigger?.closest('.js-modal')
  const modalId = parentModal?.getAttribute('data-modal-id') || parentModal?.getAttribute('data-form-id')
  const timeRadiosContainer = parentModal ? parentModal?.querySelector('.radios-content-js') : null;
  const is_discount = datesMatch(selectedDate)
  if(!parentModal) return
  if (!selectedDate) {
    timeRadiosContainer.innerHTML = '';
  } else {
    // const dayOfWeek = selectedDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
    let timesForDay = availableTimes;
    timeRadiosContainer.innerHTML = '';
    timesForDay?.forEach((time, index) => {
      const radioId = `time${ modalId ? modalId : ''}+${ index + 1}`;

      const radioDiv = document.createElement('div');
      radioDiv.className = 'online-modal__radio';

      const input = document.createElement('input');
      input.type = 'radio';
      input.name = `online-modal__radio-${modalId ? modalId : ''}`;
      input.id = radioId;
      input.value = time.value;

      // Якщо година є в масиві доступних, то не буде disabled
      input.disabled = time.disabled

      const label = document.createElement('label');
      label.setAttribute('for', radioId);
      label.innerText = time.name ? time.name : time.value;

      if(is_discount && !input.disabled){
        const discount = document.createElement('span');
        discount.className = 'custom-discount';
        discount.innerText = '-10%';
        label.appendChild(discount);
      }

      input.addEventListener('change', () => {
        formaterDate(inputTrigger, picker?.dateSelected, input.value);
      });

      radioDiv.appendChild(input);
      radioDiv.appendChild(label);
      timeRadiosContainer.appendChild(radioDiv);
    });
  }
}

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getDatesMonth = (startDay , endDay) => {
  const now = new Date();

  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return {
    firstDay:formatDate(startDay ? startDay : firstDay),
    lastDay: formatDate(endDay ? endDay : lastDay)
  };
}

async function getNextAvailableDate(inputTrigger, date, fromPicker) {
  if (!inputTrigger) return;
  
  const parentBlock = inputTrigger.closest('.modal__mini-block');

  const inputDoctor =  findInput(parentBlock?.closest('.consult-form-js'), 'doctor_id')
  const inputClinic = findInput(parentBlock?.closest('.consult-form-js'), 'clinic_id')
  
  clinicId = inputClinic?.value || null;
  doctorId = inputDoctor?.value || null;

  if(!inputDoctor && !inputClinic || !formId) {
    const {
      firstDay,
      lastDay
    } = getDatesMonth()
    const data = await getData(firstDay , lastDay , null, null, null);
    if(data) await changeEnabledDates(data)
    if(data) await parseAvailabledTimes(inputTrigger, picker, data, date, fromPicker);
  }

  inputClinic?.addEventListener('clinicChangeEvent', () => {
    clinicId = inputClinic.value;
  });
 
  if (inputDoctor?.dataset.customInputChange)
    return
  inputDoctor?.addEventListener('customInputChange',async () => {
      doctorId = inputDoctor.value || null;
      inputDoctor.value ? parentBlock.classList.remove('hidden-piker') : parentBlock.classList.add('hidden-piker');
      if(!inputDoctor || !formId) return
      const {
        firstDay,
        lastDay
      } = getDatesMonth()
      const data = await getData(firstDay , lastDay , doctorId, clinicId, formId);
      if(data) await changeEnabledDates(data)
      if(data) await parseAvailabledTimes(inputTrigger, picker, data, date, fromPicker);
  });
  inputDoctor.dataset.customInputChange = true;

}




async function changeMonthPicker(startDay , endDay , inputTrigger) {
  const {
    firstDay,
    lastDay
  } = getDatesMonth(startDay , endDay)
  const data = await getData(firstDay , lastDay , doctorId, clinicId, formId);
  if(data) await changeEnabledDates(data)
  await picker.navigate(new Date(startDay));
}

function findInput(container, name) {
  const input = container?.querySelector(`input[name="${name}"]`);
  
  if (!input) return null;
  
  if (input.type === "radio") {
      return container.querySelector(`input[name="${name}"]:checked`) || null;
  }
  
  return input;
}

async function changeEnabledDates(dataSchedule){
  const dates = Object.keys(dataSchedule);
  enabledDates = []
  enabledDates = [...new Set([...enabledDates, ...dates])];
}
async function parseAvailabledTimes(inputTrigger, picker, dataSchedule, date, fromPicker) {
  if(!dataSchedule) return
  const parsedData = dataSchedule
  const formattedDate = date 
  ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` 
  : null;  
  const dates = Object.keys(parsedData);
  const findedArray = fromPicker && formattedDate
    ? parsedData[formattedDate]
    : parsedData[dates[0]];
 
  const activeDate = date || new Date(dates[0]);
  if(!dates[0]){
    generateRadios(inputTrigger, null, findedArray, true);
    picker.setDate(null);
    return
  }
  picker.setDate(activeDate);
  picker.navigate(activeDate);
  generateRadios(inputTrigger, date || new Date(dates[0]), findedArray, true);
  formaterDate(inputTrigger, date || new Date(dates[0]));
}

export function initDatePicker(modal , id) {
  const input = modal?.querySelector('.online-modal__datepicker');
  if(!input) return;
  // if(withDoctor) {
  //   picker = datepicker(input, options);
  //   getNextAvailableDate(input , null , false);
  // }else{
  //   picker = datepicker(input, optionsDefault);
  //   findNextAvailableDate(input , null , false);
  // }

  formId = id || null
  picker = datepicker(input, options);
  getNextAvailableDate(input , null , false);
}

export function  removeDatePicker() {
  doctorId = null
  clinicId = null
  formId = null
  if(picker){
    picker.remove();
    picker = null
  }
}

const getData = async (firstDay, lastDay, doctorId, clinicId, formId) => {
  const params = new URLSearchParams();
  let action 
  // Додаємо параметри тільки якщо вони є
  if (doctorId) params.append('doctor_id', doctorId);
  if (clinicId) params.append('clinic_id', clinicId);
  if (firstDay) params.append('date_start', firstDay);
  if (lastDay) params.append('date_end', lastDay);

  if(doctorId && clinicId)action = `/api/online_appointment_forms/${formId}/schedule?${params.toString()}`;
  if(doctorId && !clinicId)action = `/api/online_advice_forms/${formId}/schedule?${params.toString()}`;
  if(!doctorId && !clinicId && !formId)action = `/api/advice_forms/schedule?${params.toString()}`;
  scheduleGlobal = null
  if(!action) return
  try {
    const response = await fetch(action, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    scheduleGlobal = data;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Проброс помилки для обробки у виклику функції
  }
};

function getDiscountsDate() {
  const serverDatetime = localStorage.getItem("server_datetime");
  if (!serverDatetime) return [];

  const dateTimeParts = serverDatetime.split(" ");
  if (dateTimeParts.length < 2) return [];

  const dateParts = dateTimeParts[0].split("-");
  if (dateParts.length !== 3) return [];

  const timeParts = dateTimeParts[1].split(":");
  if (timeParts.length < 2) return [];

  const [year, day, month] = dateParts.map(Number);
  const [hours, minutes] = timeParts.map(Number);

  const currentDate = new Date(year, month - 1, day,5);
  
  // Додаємо nextDate, якщо час >= 12:30
  const result = [currentDate];
  if (hours > 12 || (hours === 12 && minutes >= 30)) {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    result.push(nextDate);
  }
  
  return result; 
}



