import { removeDatePicker } from './online-consulting';

const NOCAPTCHA_SITEKEY = getSiteKey();
const LOCALE = document.documentElement.lang;

document.addEventListener('DOMContentLoaded', () => {
    initializeForms();
});

function getSiteKey() {
    const sitekeyElement = document.querySelector('[data-sitekey]');
    return sitekeyElement ? sitekeyElement.dataset.sitekey : '';
}

function initializeForms() {
    const forms = document.querySelectorAll('[data-form-submit]');
    if (!forms.length) return;

    forms.forEach(form => {
        if (form.dataset.listener) return;
        form.dataset.listener = "true";
        form.addEventListener('submit', handleFormSubmit);
        setupInputListeners(form);
    });
}

function setupInputListeners(form) {
    const inputs = form.querySelectorAll('input');
    
    inputs.forEach(input => {
        const eventType = (input.type === 'radio' || input.type === 'checkbox') ? 'change' : 'input';
        
        input.addEventListener(eventType, (e) => {
            const input = e.target;
            let errorBlock;

            let parent = input.parentElement;
            while (parent && !parent.querySelector('[data-error]')) {
                parent = parent.parentElement;
            }

            if (parent) {
                errorBlock = parent.querySelector('[data-error]');
            }

            if (errorBlock) {
                if (input.type === 'checkbox') {
                    const anyChecked = parent.querySelector('input[type="checkbox"]:checked');
                    if (anyChecked) {
                        errorBlock.innerHTML = "";
                    }
                } else {
                    errorBlock.innerHTML = "";
                }
            }
        });
    });
}

async function handleFormSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formType = form.dataset.formSubmit;
    const redirect = form.dataset.redirect || false;
    const triggerEvent = form.dataset.triggerEvent || 'lid_form';

    clearErrors(form, formType);
    await processFormSubmission(form, formType, redirect, triggerEvent);
}

function clearErrors(form, formType) {
    const errorBlocks = form.querySelectorAll(`[data-form-submit="${formType}"] [data-error]`);
    errorBlocks.forEach(block => block.innerHTML = '');
}

async function processFormSubmission(form, formType, redirect, triggerEvent) {
    const formData = transformFormData(new FormData(form), formType);
    const response = await submitForm(form, formData);

    if (!response) return;

    updateCaptcha(formType);
    handleFormResponse(response, formType, redirect, triggerEvent);
}

function transformFormData(formData, formType) {
    const object = Object.fromEntries(formData);
    
    if (formType !== 'survey') return object;

    const transformed = {};
    for (const [key, value] of Object.entries(object)) {
        const match = key.match(/questions\[(\d+)\]\[answers\]\[(\d+)\]/);
        if (match) {
            const [_, questionNum, answerIndex] = match;
            transformed.questions = transformed.questions || {};
            transformed.questions[questionNum] = transformed.questions[questionNum] || { answers: [] };
            transformed.questions[questionNum].answers[answerIndex] = value;
        } else {
            transformed[key] = value;
        }
    }
    return transformed;
}

async function submitForm(form, data) {
    try {
        const response = await fetch(form.action, {
            method: form.method,
            headers: {
                'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error('Form submission error:', error);
        return null;
    }
}

function updateCaptcha(formType) {
    const container = document.getElementById(`recaptcha-container-${formType}`);
    if (!container) return;

    container.innerHTML = `<div class="g-recaptcha" id="recaptcha-${formType}"></div>`;
    grecaptcha.render(`recaptcha-${formType}`, { sitekey: NOCAPTCHA_SITEKEY });
}

function handleFormResponse(data, formType, redirect, triggerEvent) {
    if (data.errors) {
        displayErrors(data.errors, formType);
    }
    
    if (data.status === true) {
        handleSuccessResponse(data, formType, redirect, triggerEvent);
    }
}

function displayErrors(errors, formType) {
    for (const [key, value] of Object.entries(errors)) {
        const formattedKey = key.split('.').reduce((acc, part, i) => 
            i === 0 ? part : `${acc}[${part}]`, '');
        
        const errorBlock = document.querySelector(`[data-form-submit="${formType}"] [data-error="${formattedKey}"]`);
        console.log(errorBlock);
        if (errorBlock) {
            errorBlock.innerHTML = `<span></span><p class="error">${value}</p>`;
        }
    }

    const firstError = document.querySelector(`[data-form-submit="${formType}"] [data-error] p.error`);
    if (firstError) {
        firstError.parentElement.querySelector('span').scrollIntoView({ behavior: 'smooth' });
    }
}

function handleSuccessResponse(data, formType, redirect, triggerEvent) {
    const body = document.querySelector('body');
    
    if (data.user_result_token && data.show_creditionals_modal) {
        showCredentialsModal(data, body);
    } else {
        showSuccessMessage(data.message, redirect, body);
    }
    
    dataLayer.push({ 'event': triggerEvent });
}

function showCredentialsModal(data, body) {
    const surveyModal = document.querySelector('.survey-js-modal');
    const form = surveyModal.querySelector('form');
    const text = surveyModal.querySelector('#surveyFormModalText');
    const button = surveyModal.querySelector('#surveyModalNextStep');

    button.addEventListener('click', () => {
        surveyModal.classList.add('survey-step-two');
    })
    text.innerHTML = data.message;
    form.action = `/${LOCALE}/user_result/${data.user_result_token}/creditionals`;
    
    surveyModal.classList.add('activeModal');
    initializeForms();
    
    surveyModal.querySelector('.close-modal-form').addEventListener('click', () => {
        surveyModal.classList.remove('activeModal');
        body.classList.remove('noScroll');
    });
}

function showSuccessMessage(message, redirect, body) {
    const modals = document.querySelectorAll('.js-modal');
    const thanks = document.querySelector('.thanks');
    const thanksText = document.querySelector('.thanks__text');

    body.classList.remove('noScroll');
    modals.forEach(modal => {
        modal.classList.remove('activeModal');
        removeDatePicker();
    });

    thanks.classList.add('thanks_active');
    thanksText.innerHTML = message;

    if (redirect) {
        setupRedirect(thanks, redirect);
    }
}

function setupRedirect(thanks, redirect) {
    thanks.querySelector('.thanks__close').addEventListener('click', () => {
        window.location.href = redirect;
    });
    
    document.body.addEventListener('click', (event) => {
        if (!event.target.closest('.thanks__block')) {
            window.location.href = redirect;
        }
    });
}