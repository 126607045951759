import {initDatePicker , removeDatePicker} from './online-consulting';
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const locale = document.documentElement.lang
const consultationDoctorContainer = document.querySelector('.consultation__doctor');
const consultationDoctorsInit = consultationDoctorContainer?.querySelectorAll('.js-choose-doctors');
const doctorsModal = document.querySelector('.modalDoctors');
const reviewsModal = document.querySelector('.reviewsDoctor');
const clinicInputs = document.querySelectorAll('.consultation input[name="clinic_id"]');
const modalsClinicsInputs = document.querySelectorAll('.modalDoctors input[name="clinic_id-c"]');
const closeButtons = document.querySelectorAll('.js-c_close');
const doctorFormValue = document.querySelector('.consultation input[name="doctor_id"]');
const form = document.querySelector('.consult-form-js')
let allDoctors
let checkedDoctorId = null


const body = document.body;
let chosenDoctor

if (doctorsModal) {
  consultationDoctorsInit?.forEach(btn => {
    btn.addEventListener('click', async () => {
      const selectedClinic = Array.from(clinicInputs).find(input => input?.checked)?.value;
      const doctors = await getDoctors(selectedClinic);
      toggleModals(doctorsModal, doctors);
    });
  });

  closeButtons?.forEach(btn => {
    btn.addEventListener('click', () => {
      closeModals();
    });
  });

  const toggleModals = (activeModal, data) => {
    closeModals()
    activeModal.classList.toggle('active-modal');
    body.classList.toggle('noScroll');

    if (activeModal.classList.contains('modalDoctors')) {
      manipulateDoctorsModal(data);
    } else if (activeModal.classList.contains('reviewsDoctor')) {
      manipulateReviewsModal(data);
    }
  };

  const manipulateDoctorsModal = (doctors) => {
    if (doctors.data && !doctors.data.length) {
      doctorsModal.classList.add('show-error');
    }else{
      const content = doctorsModal.querySelector('.c-modal__doctors');
      content.innerHTML = '';
      doctors.data.forEach(doctor => {
        content.insertAdjacentHTML('beforeend', doctorCard(doctor));
      });
      updateDoctorsEvents()
    }
  };


  const updateDoctorsEvents =  () => {
    allDoctors = document.querySelectorAll('.c-doctor');
    allDoctors.forEach(doctor => {
      doctor.addEventListener('click',async (event) => {
        if (event.target.type === 'radio') return;
        const dataDoctor = getDataAttribute(doctor, 'doctor');
        const doctorObject = JSON.parse(dataDoctor);
        if (event.target.closest('.c-doctor__show-reviews')) {
          event.preventDefault(); 
          const reviews = await getReviews(doctorObject.id);
          toggleModals(reviewsModal, reviews);
          return; 
        }
        chooseDoctor(doctorObject);
      });
    } )
  }


  const chooseDoctor = (doctor) => {
    chosenDoctor = doctor
    checkedDoctorId = doctor.id
    const content = consultationDoctorContainer.querySelector('.consultation__doctor-js-return')
    consultationDoctorContainer.classList.add('has-doctor')
    content.innerHTML = '';
    content.insertAdjacentHTML('beforeend', doctorCard(doctor));
    removeDatePicker();
    initDatePicker(form , form.getAttribute('data-form-id'));
    doctorFormValue.value = doctor.id
    doctorFormValue.dispatchEvent(new Event('customInputChange'));
    closeModals();
    updateDoctorsEvents();
  }


  const clearChosenDoctor = () => { 
    consultationDoctorContainer.classList.remove('has-doctor')
    const content = consultationDoctorContainer.querySelector('.consultation__doctor-js-return')
    content.innerHTML = '';
    checkedDoctorId = null
    removeDatePicker();
  }

  modalsClinicsInputs.forEach(input => {
    input.addEventListener('change', () => {
      clinicInputs.forEach(clinicInput => {
        if (clinicInput.value === input.value) {
          clinicInput.checked = true;
          closeModals();
          const firstBtn = consultationDoctorsInit[0];
          firstBtn.click();
        }
      });
    });
  });

  clinicInputs.forEach(input => {
    input.addEventListener('change', () => {
      clearChosenDoctor(),
      doctorFormValue.value = null
      input.dispatchEvent(new Event('clinicChangeEvent'));
      doctorFormValue.dispatchEvent(new Event('customInputChange'));
      removeDatePicker();
    })
  })



  const manipulateReviewsModal = (reviews) => {
    const swiperWrapper = document.querySelector('.swiper-review-consultation .swiper-wrapper');
    
    reviews?.data.forEach(review => {
      const reviewSlide = document.createElement('div'); // Створюємо новий слайд
      reviewSlide.classList.add('swiper-slide'); // Додаємо клас для слайду
      
      // Генеруємо контент для слайду
      reviewSlide.innerHTML = reviewCard(review);
      
      // Додаємо слайд до swiper-wrapper
      swiperWrapper.appendChild(reviewSlide);
    });
    
    // Ініціалізуємо Swiper після додавання слайдів
    swiperForReviews('.swiper-review-consultation', 1); // 1 - це початкова кількість слайдів
  };
  

  const handleClickOutside = (event) => {
    if (doctorsModal.classList.contains('active-modal')) {
      if (!event.target.closest('.modalDoctors .c-modal__wrapper')) {
        closeModals();
      }
    } else if (reviewsModal.classList.contains('active-modal')) {
      if (!event.target.closest('.reviewsDoctor .c-modal__wrapper')) {
        closeModals();
      }
    }
  };

  const closeModals = () => {
    doctorsModal?.classList.remove('active-modal');
    reviewsModal?.classList.remove('active-modal');
    doctorsModal?.classList.remove('show-error');
    body.classList.remove('noScroll');
    const swiperWrapper = document.querySelector('.swiper-review-consultation .swiper-wrapper');
    swiperWrapper.innerHTML = '';

  };

  document.addEventListener('click', handleClickOutside);

  const getDoctors = async (clinic_id) => {
    const params = new URLSearchParams();
    if (clinic_id) params.append('clinic_id', clinic_id);

    const action = `/${locale}/api/online_appointment_forms/doctors?${params.toString()}`;

    try {
      const response = await fetch(action, {
        method: 'GET',
        headers: {
          'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  const getReviews = async (doctor_id) => {
    const params = new URLSearchParams();
    if (doctor_id) params.append('doctor_id', doctor_id);

    const action = `/${locale}/api/online_appointment_forms/doctors/${doctor_id}/reviews`;

    try {
      const response = await fetch(action, {
        method: 'GET',
        headers: {
          'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
          'Content-Type': 'application/json', 
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
}


const doctorCard = (doctor) => {

  return `
        <label for="doctor-${doctor?.id}" data-doctor='${ JSON.stringify(doctor)}' class="c-doctor">
            <div class="c-doctor__img">
                <img src="${doctor?.logo}" alt="">
            </div>
            <p class="c-doctor__title">${doctor?.name}</p>
            
            <p class="c-doctor__subtitle">${doctor?.description}</p>
            <p class="c-doctor__price">${getDataAttribute(document.querySelector('.there_are_all_keys') , 'keyprice')}  <span>${doctor?.price}</span></p>
            <span class="c-doctor__show-reviews">${getDataAttribute(document.querySelector('.there_are_all_keys') , 'keyshowreviews')}  (${doctor?.reviews_count}) </span>
           <input ${doctor?.id === checkedDoctorId ? 'checked' : ''} type="radio" name="doctor" id="doctor-${doctor?.id}">
        </label>
  `
  
} 

const reviewCard = (review) => {
  const stars = review?.rating 
    ? Array.from({ length: review.rating }, () => `
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 18.26L4.94701 22.208L6.52201 14.28L0.587006 8.792L8.61401 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26Z" fill="#EAB327"></path>
        </svg>
      `).join('')
    : '';

  return `
    <div class="response__cart-content-text response__cart-content-text_pop-up">
      <div class="response__cart-block">
        <div class="response__cart-info">
          <p class="response__cart-author h4">${review?.author_name}</p>
        </div>
        <div class="response__cart-rait">
          ${stars}
        </div>
      </div>
    
      <div class="response__cart-block response__cart-block_content response__cart-text small-text">
        ${review?.text}
      </div>    
      <div class="reviews__date reviews__date_for-slider">
        <div class="date">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3335 2.00033H14.0002C14.177 2.00033 14.3465 2.07056 14.4716 2.19559C14.5966 2.32061 14.6668 2.49018 14.6668 2.66699V13.3337C14.6668 13.5105 14.5966 13.68 14.4716 13.8051C14.3465 13.9301 14.177 14.0003 14.0002 14.0003H2.00016C1.82335 14.0003 1.65378 13.9301 1.52876 13.8051C1.40373 13.68 1.3335 13.5105 1.3335 13.3337V2.66699C1.3335 2.49018 1.40373 2.32061 1.52876 2.19559C1.65378 2.07056 1.82335 2.00033 2.00016 2.00033H4.66683V0.666992H6.00016V2.00033H10.0002V0.666992H11.3335V2.00033ZM10.0002 3.33366H6.00016V4.66699H4.66683V3.33366H2.66683V6.00033H13.3335V3.33366H11.3335V4.66699H10.0002V3.33366ZM13.3335 7.33366H2.66683V12.667H13.3335V7.33366Z" fill="#515668"></path>
          </svg>
          <span>${review?.date}</span>
        </div>
      </div>
    </div>
  `;
};



const getDataAttribute = (element, attribute) => {
  return element?.dataset?.[attribute] || null;
};



const swiperForReviews = (selector, slides) => {
  const swiper = new Swiper(selector, {
      modules: [ Navigation, Pagination ],
      // Default parameters
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
          nextEl: selector+' .swiper-button-next',
          prevEl: selector+' .swiper-button-prev',
      },
      pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false,
      },
      breakpoints: {
          300:{
              slidesPerView: 1,
              spaceBetween: 20,
          },
          575:{
              slidesPerView: 2,
          },
          768: {
              slidesPerView: 2,
          },
          991: {
              slidesPerView: 2,
          },
          1100: {
              slidesPerView: 3, // Виправлено з "slidesPerview" на "slidesPerView"
          }
      }
  })
};

